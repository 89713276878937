import de from '#app/modules/i18n/locales/de'
import en from '#app/modules/i18n/locales/en'

// List of languages your application supports.
export const supportedLngs = ['de', 'en']

// Fallback language will be used if the user language is not in the supportedLngs.
export const fallbackLng = 'de'

// Default namespace of i18next is "translation", but you can customize it here.
export const defaultNS = 'translation'

export const resources = {
  en: { translation: en },
  de: { translation: de },
}
