import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import i18next from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { startTransition, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { getInitialNamespaces } from 'remix-i18next/client'
import * as i18n from '#app/modules/i18n/i18n'
import posthog from 'posthog-js'

Sentry.init({
    dsn: "https://cac6362755c500da237c51caaaecc890@o4507527316701184.ingest.de.sentry.io/4507527321747536",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }), Sentry.replayIntegration()]
})

function PosthogInit() {
  useEffect(() => {
    posthog.init('phc_RPDPNipNhtWKUtM5nDnQoHMEOGJ8eqt019mr5H8w0I6', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    })
  }, [])

  return null
}

async function main() {
  await i18next
    .use(initReactI18next) // Initialize `react-i18next`.
    .use(I18nextBrowserLanguageDetector) // Setup client-side language detector.
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      detection: {
        // Enable HTML tag detection only by detecting the language server-side.
        // Using `<html lang>` attribute to communicate the detected language to the client.
        order: ['htmlTag'],
        // Since we solely utilize htmlTag, browser language caching is unnecessary.
        caches: [],
      },
    })

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <RemixBrowser />
        <PosthogInit />
      </I18nextProvider>,
    )
  })
}

main().catch((error) => console.error(error))