export default {
  //landing page ----------------
  hero_title: 'Build Your Personal Real Estate Brand Online',
  hero_description:
    'Acquire more real estate buyers and sellers online in under 2 minutes.',
  claim_username_cta: 'Claim Username',

  social_media_feature_title: 'Link your Instagram, TikTok, Linkedin or other bios',
  social_media_feature_description:
    'immotr.ee empowers creators to turn every link into an opportunity',
  get_started_cta: 'Get Started For Free',
  conversion_feature_title: 'Convert your social media traffic into leads ',
  conversion_feature_description:
    'immotr.ee converts 2-3X as good as traditional landing pages',
  listing_showcase_feature_title: 'Showcase your recent listings',
  listing_showcase_feature_description:
    'Add your top listings and reviews to your profile to acquire buyers through social media traffic.',
  automation_feature_title: 'Simple automations to save you time',
  automation_feature_description:
    'Setup automatic responses to quickly and efficiently work on seller inquiries or property requests.',
  about_nav: 'About',
  features_nav: 'Features',
  why_choose_immotree: 'Why Choose Immotree Over Standard Solutions?',
  join_100_real_estate_agents_across_germany:
    'Join 100+ real estate agents across Germany',
  we_help_you_grow_your_business: 'We help you grow your real estate business',
  proplinkio_app_screenshot: 'Immotree app screenshot',
  tailored_for_real_estate_agents: 'Tailored for Real Estate Agents',
  tailored_description:
    'Unlike generic funnel builders, Immotree is specifically designed for real estate agents.',
  quick_and_easy_setup: 'Quick and Easy Setup',
  quick_setup_description:
    'Get your professional Micro-site up and running in no time, with minimal technical knowledge required.',
  comprehensive: 'Comprehensive Features',
  comprehensive_description:
    'Built-in sales funnel, video embedding, Immotree provides all the tools you need to elevate your personal brand.',

  feature: 'Feature',
  funnel_builders: 'Funnel Builders',
  custom_software: 'Custom Software by Agency',
  professional_micro_site: 'Professional Micro-Site',
  brand_customization: 'Brand Customization',
  visitor_analytics: 'Visitor Analytics',
  crm_integration: 'CRM Integration',
  built_in_funnels: 'Built-in Funnels',
  professional_reviews: 'Professional Reviews',
  video_embedding: 'Video Embedding',
  agent_to_agent_referrals: 'Agent-to-Agent Referrals',
  easy_to_setup: 'Easy to setup',
  maintenance: 'Maintenance',
  price: 'Price',
  immotree_price: '<99€/month after trial',
  funnel_builders_price: '€65/month to €199/month',
  custom_software_price: '€5.000 to €10.000',
  start_your_journey_now: 'Start your journey now',
  username_placeholder: 'yourusername',
  reserve_your_name: 'Reserve your name',
  reserve_your_name_description:
    'It’s still free and available. Get your unique link now',
  reserve_your_name_cta: 'Reserve Your immotree',
  //End landing page ----------------
  //pricing.tsx ----------------
  pricing: 'Pricing',
  pricing_plans_title: 'Pricing plans for self-employed agents and teams',
  pricing_plans_description:
    "Whether you're a self-employed agent or a real estate company, our pricing is both flexible and scalable. ",
  payment_frequency: 'Payment frequency',
  monthly: 'Monthly',
  annually: 'Annually',
  '/month': '/month',
  '/year': '/year',
  starter_plan: 'Starter',
  plus_plan: 'Plus',
  business_plan: 'Business',
  starter_plan_description: "A plan that's perfect for getting started with Proplink.io.",
  plus_plan_description: 'A plan that adds more features to help you grow.',
  business_plan_description: 'A plan that adds even more features to help you succeed.',
  free_microsite: 'Free Microsite',
  custom_branding: 'Custom branding',
  analytics_dashboard: 'Analytics dashboard',
  lead_widgets: 'Lead widgets',
  business_support: '24/7 Business support',
  proplink_marketing_pixel: 'Proplink Marketing Pixel',
  unlimited_seller_leads: 'Unlimited seller leads per month',
  most_popular: 'Most popular',
  buy_plan: 'Buy plan',
  //pricing.tsx ----------------
  dashboard_title: 'Dashboard',
  start_now_cta: 'Start Now',
  dashboard_description: 'Manage your public page',
  dashboard_getstarted: 'Get Started',
  dashboard_getstarted_description: 'Create your public page',
  submitting_data: 'Submitting data...',
  dasboard_bio: 'Bio',
  preview: 'Preview',
  dashboard_bio_description: 'Tell us about yourself',
  dashboard_bio_placeholder: 'Write a short bio about yourself',
  dashboard_profile_analytics: 'Analytics Pixels',
  dashboard_profile_save: 'Save',
  dashboard_profile_bg_color: 'Background Color',
  dashboard_profile_publish: 'publish',
  dashboard_profile_social_links: 'Social Links',
  dasbhboard_profile_customer_reviews: 'Customer Reviews',
  dashboard_profile_recent_listing: 'Recent Listings',
  dashboard_profile_text_color: 'Text Color',
  is_agent: 'are you a real estate agent?',
  you_have_more_leads_than_allowed:
    "You're currently on the free plan, which allows you to view up to 5 leads.Upgrade your plan to access all your leads and unlock more features!",
  leads_chart_label: 'Leads Breakdown',
  listings_edit_button: 'Edit',
  listings_delete_button: 'Delete',
  listings_add_button: 'Create New Listing',
  listings_update_button: 'Update Property',
  form_data_privacy:
    'Our General Terms and Conditions apply and you will find information on how we process your personal data in our Privacy Policy.',
  reviews_title: 'Reviews',
  add_review: 'Add Review',
  edit: 'Edit',
  edit_review: 'Edit Review',
  create_review: 'Create Review',
  delete: 'Delete',
  confirm_deletion: 'Confirm Deletion',
  delete_review_confirmation:
    'Are you sure you want to delete the review "{title}" by {reviewer}? This action cannot be undone.',
  cancel: 'Cancel',
  deleting: 'Deleting...',
  title: 'Title',
  reviewer: 'Reviewer',
  next_step: 'Next Step',
  description: 'Description',
  rating: 'Rating',
  avatar: 'Avatar',
  select_rating: 'Select rating',
  out_of_5: 'out of 5',
  update_review: 'Update Review',
  creating: 'Creating...',
  updating: 'Updating...',
  enter_review_title: 'Enter review title',
  enter_reviewer_full_name: 'Enter the reviewer full name',
  enter_review_description: 'Enter review description',
  listings_intro: 'Showcase your properties and get more leads',
  fantastic: 'Fantastic!',
  great: 'Great!',
  fill_info_prompt: "Let's fill in some info about you.",
  avatar_size_error: 'Avatar image File size must be less than 5MB.',
  enter_full_name: 'Enter your full name',
  enter_subheading: 'Enter your subheading',
  reviews_intro: 'Showcase your reviews and build trust with your customers',
  bio: 'Bio',
  phone: 'Phone',
  email: 'Email',
  continue: 'Continue',
  update_info_anytime:
    'You can update these informations at any time from your account settings.',

  currency: 'Currency',
  select_currency: 'Select currency',
  purpose: 'Purpose',
  select_purpose: 'Select purpose',
  rent: 'Rent',
  sale: 'Sale',
  interval: 'Interval',
  select_interval: 'Select interval',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  type: 'Type',
  select_type: 'Select type',
  apartment: 'Apartment',
  house: 'House',
  condo: 'Condo',
  townhouse: 'Townhouse',
  location: 'Location',
  size: 'Size',
  units: 'Units',
  select_units: 'Select units',
  sqft: 'sq ft',
  sqm: 'sq m',
  bedrooms: 'Bedrooms',
  bathrooms: 'Bathrooms',
  current_images: 'Current Images',
  property_image: 'Property Image',
  marked_for_removal: 'Marked for removal',
  add_new_images: 'Add New Images',
  new_images_to_upload: 'New Images to Upload',
  new_upload: 'New upload',

  your_avatar: 'Your Avatar',
  avatar_description: 'This is your avatar. It will be displayed on your profile.',
  avatar_upload_instruction:
    'Click on the avatar to upload a custom one from your files.',
  reset: 'Reset',
  your_username: 'Your Username',
  username_description: 'This is your username. It will be displayed on your profile.',
  username: 'Username',
  username_character_limit: 'Please use 32 characters at maximum.',
  save: 'Save',
  delete_account: 'Delete Account',
  delete_account_description:
    'Permanently delete your Proplist account, all of your projects, links and their respective stats.',
  delete_account_warning: 'This action cannot be undone, proceed with caution.',
  are_you_sure: 'Are you sure?',

  immotree_billing: 'Immotree Billing',
  manage_plans_and_billings: 'Here you can manage plans and billings',
  plan: 'Plan',
  current_plan_status: 'You are currently on the {{planName}} plan.',
  free: 'Free',
  month: 'month',
  year: 'year',
  expires: 'Expires',
  renews: 'Renews',
  on: 'on',
  upgrade_to_pro: 'Upgrade to PRO',
  manage_subscription: 'Manage Subscription',
  update_payment_info: 'Update your payment method, billing address, and more.',
  stripe_redirect_notice: 'You will be redirected to the Stripe Customer Portal.',
  manage: 'Manage',

  update_property: 'Update Property',
  create_property: 'Create Property',
  delete_property_confirmation: 'Are you sure you want to delete this property?',
  edit_property: 'Edit Property',
  listings: 'Listings',
  create_new_property: 'Create New Property',
  property_details: '{{bedrooms}} bed | {{bathrooms}} bath | {{size}} {{units}}',

  leads_view_button: 'View Lead',

  settings_title: 'Settings',
  settings_description: 'Manage your account settings',

  settings_avatar_title: 'Your Avatar',
  settings_avatar_description:
    'this is your avatar. it will be displayed on your profile',
  settings_avatar_change_instruction:
    'Click on the avatar to upload a custom one from your files.',

  settings_username_title: 'Your Username',
  settings_username_description:
    'This is your use rname. It will be displayed on your profile',
  settings_username_constraint: 'Please use 32 characters at maximum.',
  settings_username_save_button: 'Save',

  settings_account_delete_title: 'Delete Account',
  settings_account_delete_description:
    'Permanently delete your Proplist account, all of your projects, links and their respective stats.',
  settings_account_delete_button: 'Delete Account',

  settings_nav_general: 'General',
  settings_nav_billing: 'Billing',

  settings_billing_title: 'Billing',
  settings_billing_description: 'Manage billing and your subscription plan.',

  settings_billing_plan_title: 'Proplink Billing',
  settings_billing_plan_description: 'Here you can manage plans and billings',
  settings_billing_plan_current: 'Plan',
  settings_billing_plan_upgrade: 'Upgrade to Pro',
  settings_billing_manage_button: 'Manage',
  settings_billing_manage_title: 'Manage Subscription',
  settings_billing_manage_description:
    'Update your payment method, billing address, and more.',
  settings_billing_manage_redirect_hint:
    'You will be redirected to the Stripe Customer Portal.',

  lead_widget_qs_property_type: 'What type of property is it?',
  lead_widget_land: 'Land',
  lead_widget_house: 'House',
  lead_widget_apartment: 'Apartment',
  lead_widget_commercial_space: 'Commercial',
  lead_widget_qs_property_size: 'What is the size of the property?',
  label_size: 'Size',
  placeholder_size: 'Enter size',
  button_next: 'Next',
  button_previous: 'Previous',
  lead_widget_qs_bedroom: 'How many bedrooms does the property have?',
  label_slider: 'Number of bedrooms',
  lead_widget_qs_build_year: 'When was the property built?',
  lead_widget_build_year_label: 'Build Year',
  lead_widget_qs_property_vacancy: 'Is the property currently occupied?',
  lead_widget_qs_property_vacancy_rented: 'Rented',
  lead_widget_qs_property_vacancy_vacant: 'Vacant',
  lead_widget_qs_property_condition: 'What is the condition of the property?',
  lead_widget_conditon_new: 'New / Like New',
  lead_widget_conditon_renovation_needed: 'Needs Renovation',
  lead_widget_conditon_well_maintained: 'Well Maintained',
  lead_widget_qs_property_transaction: 'What type of transaction are you interested in?',
  lead_widget_qs_property_transaction_sale: 'Sell',
  lead_widget_qs_property_transaction_buy: 'Buy',
  lead_widget_qs_property_transaction_rent: 'Rent',
  lead_widget_qs_property_timeframe: 'What is your timeframe?',
  lead_widget_qs_property_timeframe_3months: 'Within 3 months',
  lead_widget_qs_property_timeframe_6months: 'Within 6 months',
  lead_widget_qs_property_timeframe_6_12months: '6-12 months',
  lead_widget_qs_property_timeframe_12months_plus: 'More than 1 year',

  lead_widget_qs_property_occupation: 'What is the current occupation status?',
  lead_widget_qs_property_occupation_owner: 'Owner-occupied',
  lead_widget_qs_property_occupation_rented: 'Rented out',
  lead_widget_qs_postalCode_heading: 'What is the postal code of the property?',
  lead_widget_postalCode_label: 'Postal Code',
  lead_widget_personal_info: 'Who should receive the property valuation?',
  lead_widget_personal_info_description: 'Please provide your personal details',
  page_views: 'Page Views',
  page_views_description: 'The number of times your page has been viewed',
  conversion_rate: 'Conversion Rate',
  pageview_vs_funnel_start: 'Pageviews vs Funnel Start',
  funnel_complete_vs_funnel_start: 'Funnel Complete vs Funnel Start',
  funnel_chart_label: 'Funnel vs page views',
  funnel_start: 'Funnel Start',
  funnel_complete: 'Funnel Complete',

  title_label: 'Title',
  public_profile_not_found: ' Nothing here yet! this could be your public profile',
  public_profile_not_found_cta: 'secure your username now',
  select_value_placeholder: 'Select a title',
  title_mr: 'Mr',
  title_mrs: 'Mrs',
  title_other: 'Other',
  city: 'City',
  full_name_label: 'Full Name',
  firstName_label: 'First Name',
  firstName_placeholder: 'First name',
  lastName_label: 'Last Name',
  lastName_placeholder: 'Last name',
  lead_widget_qs_phone_number: 'What is your phone number?',
  lead_widget_phone_number_label: 'Phone Number',
  lead_widget_qs_email: 'What is your email address?',
  lead_widget_email_label: 'Email Address',
  lead_widget_thank_you: 'Thank You!',
  lead_widget_thank_you_description:
    "We've received your information and will be in touch soon.",

  public_profile_property_valuation_header: 'Get a Free Property Valuation',

  dashboard_personal_accounti_label: 'Personal Account',
  settings_billing_plan_upgrade_button: 'Upgrade to Pro',
  dashboard_nav_settings: 'Settings',
  dashboard_nav_language: 'Language',
  dashboard_nav_logout: 'Logout',
  dashboard_nav_admin: 'Admin',
  dashboard_nav_dashboard: 'Dashboard',
  dashboard_nav_listings: 'Listings',
  dashboard_nav_reviews: 'Reviews',
  dashboard_nav_leads: 'Leads',
  dashboard_nav_analytics: 'Analytics',
  dashboard_nav_billing: 'Billing',
}
